<template>
  <v-container v-if="renderNow">
    <dialog-delete
      v-model="showCommentDeleteDialogue"
      item-text="Comment"
      @confirm-remove="deleteComment(commentIdToDelete)"
    ></dialog-delete>
    <v-row>
      <v-col>
        <div style="font-size: 20px" class="mb-6">
          <v-btn
            class="mr-2"
            color="black"
            icon
            large
            @click="
              $router.push('/students/announcements')
            "
          >
            <v-icon>arrow_back</v-icon>
          </v-btn>
          {{ !$language ? "Announcement Details" : "বিস্তারিত নোটিশ " }}
        </div>
        <div style="max-height: 90vh; overflow-y: auto; overflow-x: hidden">
          <div flat class="announcement-details-card mb-6 pt-4 px-4">
            <v-row
              class="pt-4"
              style="padding-left: 30px"
              justify="space-between"
            >
              <div style="max-width: 660px">
                <div style="font-size: 18px">{{ announcement.title }}</div>
                <div style="font-size: 12px; color: #a4a4a4">
                  {{ !$language ? "Upload Date :" : "প্রকাশের তারিখ :" }}
                  {{
                    !$language
                      ? announcement.date_time.split(".")[0]
                      : new Date(
                          announcement.date_time.split(".")[0]
                        ).toLocaleString("bn-BD", { dateStyle: "medium" })
                  }}
                </div>
              </div>
              <div style="display: flex; padding-right: 20px">
                <div class="mt-1">
                  <v-chip
                    style="font-size: 10px; border-radius: 5px"
                    color="#0099DC1A"
                    >{{ announcement.course_title }}
                  </v-chip>
                </div>
              </div>
            </v-row>
            <v-row style="font-size: 12px; color: #5b5c5e" class="px-4">
              <v-col v-html="announcement.text"></v-col>
            </v-row>
            <div style="font-size: 14px" class="ml-4">
              {{ !$language ? "Comments from batches" : "ব্যাচ থেকে মন্তব্য" }}
            </div>
            <v-tabs
              v-model="tab"
              hide-slider
              style="
                border: #00000033 1px solid;
                border-radius: 10px;
                max-width: 96%;
              "
              class="mt-3 ml-4"
            >
              <v-tab
                v-for="(batch, i, j) in announcement.shared_with_batches"
                :key="i"
                style="
                  text-transform: none;
                  max-height: 20px;
                  min-height: 20px;
                  display: inline-block;
                  margin-top: 10px;
                "
              >
                <v-chip
                  :dark="isTabActive(j)"
                  :outlined="!isTabActive(j)"
                  :color="isTabActive(j) ? '#0099DC' : '#D4D4D4'"
                  style="
                    border-radius: 6px;
                    height: 26px;
                    font-weight: normal;
                    font-size: 12px;
                  "
                  :style="!isTabActive(j) ? `color: #000000;` : ``"
                  >{{ batch }}
                </v-chip>
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
              <v-tab-item v-for="(batch_id, i) in batch_ids" :key="i">
                <comments
                  :key="refreshComments"
                  :announcement-id="announcement.announcement_id"
                  :course-id="announcement.course_id"
                  :batch-id="batch_id"
                  @show-comment-delete-dialogue="
                    showCommentDeleteDialogue = true;
                    commentIdToDelete = $event;
                  "
                ></comments>
              </v-tab-item>
            </v-tabs-items>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import announcementService from "#ef/announcement/services/AnnouncementService";
import Comments from "#ef/announcement/components/Comments";
import DialogDelete from "@ef/global/components/DialogDelete";

export default {
  name: "AnnouncementDetails",
  components: {
    Comments,
    DialogDelete
  },
  props: {
    announcement_id: String,
    course_id: String,
    partner_code: String
  },
  data() {
    return {
      refreshComments: 1, // must not be 0 initially
      commentIdToDelete: undefined,
      showCommentsListKey: [],
      showEditDeleteMenu: false,
      showCommentDeleteDialogue: false,
      batch_ids: [],
      batch_names: [],
      announcement: undefined,
      renderNow: false,
      tab: 0,
      numOfComments: 16,
      numOfBatchesShared: 3,
      shareDateMenu: false
    };
  },
  async created() {
    try {
      this.announcement = await announcementService.get(
        this.announcement_id,
        this.course_id,
        this.partner_code
      );
    } catch (e) {
      this.$root.$emit("alert", [undefined, e.message]);
    }
    for (let key in this.announcement.shared_with_batches) {
      this.batch_ids.push(key);
      this.batch_names.push(this.announcement.shared_with_batches[key]);
    }
    this.renderNow = true;
  },
  methods: {
    isTabActive(index) {
      return index === this.tab;
    },
    reRenderComments() {
      this.refreshComments++;
    },
    async deleteComment(commentIdToDelete) {
      this.showCommentDeleteDialogue = true;
      try {
        await announcementService.delete_comments(
          commentIdToDelete,
          this.announcement_id
        );
        this.reRenderComments();
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
        this.reRenderComments();
      }
    }
  }
};
</script>

<style>
.v-input .v-label {
  font-size: 12px !important;
}
</style>
<style scoped>
.inner-circle {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  border-style: solid;
  border-width: 2px;
  line-height: 44px;
  border-color: #0099dc;
  background-color: #ebf2ff;
  color: #0099dc;
  font-size: 14px;
}

.course-label {
  padding-top: 6px;
  height: 30px;
  text-align: center;

  background: #0099dc1a 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 4px #0000001a;
  border-radius: 5px;
  opacity: 1;
}

.batch-label {
  padding-top: 6px;
  height: 30px;
  text-align: center;
  justify-content: center;
  background: #fc62221a 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 4px #0000001a;
  border-radius: 5px;
  opacity: 1;
}

.announcement-details-card {
  max-width: 760px;
  min-height: 240px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 4px #0000001a;
  border: 1px solid #d4d4d4;
  border-radius: 10px;
  opacity: 1;
}
</style>
