var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.renderNow
    ? _c(
        "div",
        [
          _c("v-row", {
            staticClass: "pl-8",
            staticStyle: { "font-size": "12px", color: "#202020" },
          }),
          _c(
            "v-row",
            { staticClass: "px-7 mt-4" },
            [
              _c("v-text-field", {
                ref: "commentbox",
                staticStyle: { "font-size": "16px" },
                attrs: {
                  label: !_vm.$language
                    ? "Write your comment..."
                    : "আপনার মন্তব্য লিখুন",
                  outlined: "",
                  dense: "",
                  height: "30px",
                },
                on: {
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.post_comments(undefined, _vm.text)
                  },
                },
                model: {
                  value: _vm.text,
                  callback: function ($$v) {
                    _vm.text = $$v
                  },
                  expression: "text",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "comment-section" },
            _vm._l(_vm.comments, function (comment, i) {
              return _c(
                "div",
                { key: i, staticClass: "mb-4 ml-4 comment" },
                [
                  _c(
                    "v-row",
                    [
                      _c("div", { attrs: { cols: "1" } }, [
                        _c("div", { staticClass: "inner-circle mt-7 mr-2" }, [
                          _vm._v(" " + _vm._s(comment.commenter_name[0]) + " "),
                        ]),
                      ]),
                      _c("v-col", { staticClass: "ml-0" }, [
                        _c(
                          "div",
                          { staticClass: "ml-1" },
                          [
                            _c("v-row", { staticClass: "commenter-name" }, [
                              _vm._v(
                                " " + _vm._s(comment.commenter_name) + " "
                              ),
                            ]),
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "comment-text",
                                    attrs: { cols: "10" },
                                  },
                                  [_vm._v(_vm._s(comment.text))]
                                ),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "comment-actions-button",
                                    attrs: { cols: "2" },
                                  },
                                  [
                                    comment.commenter_id ===
                                    _vm.$store.state.user.user.user_id
                                      ? _c(
                                          "v-menu",
                                          {
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function ({ on, attrs }) {
                                                    return [
                                                      _c(
                                                        "v-icon",
                                                        _vm._g(
                                                          _vm._b(
                                                            {
                                                              attrs: {
                                                                size: "28",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    _vm.showEditDeleteMenu =
                                                                      !_vm.showEditDeleteMenu
                                                                  },
                                                              },
                                                            },
                                                            "v-icon",
                                                            attrs,
                                                            false
                                                          ),
                                                          on
                                                        ),
                                                        [
                                                          _vm._v(
                                                            "mdi-dots-vertical"
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          },
                                          [
                                            _c(
                                              "v-list",
                                              [
                                                _c(
                                                  "v-list-item",
                                                  {
                                                    staticStyle: {
                                                      "font-size": "12px",
                                                      color: "#0089CB",
                                                      "min-height": "26px",
                                                      "max-height": "26px",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.editText =
                                                          comment.text
                                                        _vm.startEditingComment(
                                                          i
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        staticStyle: {
                                                          color: "#0089CB",
                                                        },
                                                      },
                                                      [_vm._v("edit")]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          color: "#0089CB",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "  " +
                                                            _vm._s(
                                                              !_vm.$language
                                                                ? "Edit"
                                                                : "এডিট করুন"
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c("v-divider", {
                                                  staticClass: "my-2",
                                                  staticStyle: {
                                                    margin: "0px",
                                                    "background-color":
                                                      "#0089CB",
                                                    opacity: "0.1",
                                                  },
                                                }),
                                                _c(
                                                  "v-list-item",
                                                  {
                                                    staticStyle: {
                                                      "font-size": "12px",
                                                      color: "#0089CB",
                                                      "min-height": "26px",
                                                      "max-height": "26px",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.$emit(
                                                          "show-comment-delete-dialogue",
                                                          comment.comment_id
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        staticStyle: {
                                                          color: "#0089CB",
                                                        },
                                                      },
                                                      [_vm._v("delete")]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          color: "#0089CB",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "  " +
                                                            _vm._s(
                                                              !_vm.$language
                                                                ? "Delete"
                                                                : "ডিলিট করুন"
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm.editing[i] === false
                              ? _c(
                                  "div",
                                  [
                                    _c("v-row", {}),
                                    _c("v-row", { staticClass: "mt-4" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "ml-0",
                                          staticStyle: { width: "70px" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.reply(
                                                comment.commenter_name
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { size: "16" } },
                                            [_vm._v("reply")]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "ml-1 mt-1",
                                              staticStyle: {
                                                "font-size": "12px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  !_vm.$language
                                                    ? "Reply"
                                                    : "উত্তর দিন"
                                                )
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("div", [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "mt-2",
                                            staticStyle: {
                                              "font-size": "12px",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.date_diff(comment.date_time)
                                              )
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]),
                                  ],
                                  1
                                )
                              : _c(
                                  "div",
                                  { staticClass: "ml-0 mr-5" },
                                  [
                                    _c("v-text-field", {
                                      attrs: { outlined: "", dense: "" },
                                      on: {
                                        keydown: function ($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          )
                                            return null
                                          _vm.post_comments(
                                            comment.comment_id,
                                            _vm.editText
                                          )
                                          _vm.editing[i] = false
                                        },
                                      },
                                      model: {
                                        value: _vm.editText,
                                        callback: function ($$v) {
                                          _vm.editText = $$v
                                        },
                                        expression: "editText",
                                      },
                                    }),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          float: "right",
                                          "justify-items": "flex-end",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              outlined: "",
                                              color: "red",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.stopEditingComment(i)
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                !_vm.$language
                                                  ? "cancel"
                                                  : "বাতিল করুন"
                                              )
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "ml-4",
                                            staticStyle: {
                                              "border-radius": "5px",
                                            },
                                            attrs: {
                                              color: "#0099DC",
                                              dark: "",
                                            },
                                            on: {
                                              click: function ($event) {
                                                _vm.post_comments(
                                                  comment.comment_id,
                                                  _vm.editText
                                                )
                                                _vm.editing[i] = false
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                !_vm.$language
                                                  ? "save"
                                                  : "সেভ করুন"
                                              )
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            0
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }