var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.renderNow
    ? _c(
        "v-container",
        [
          _c("dialog-delete", {
            attrs: { "item-text": "Comment" },
            on: {
              "confirm-remove": function ($event) {
                return _vm.deleteComment(_vm.commentIdToDelete)
              },
            },
            model: {
              value: _vm.showCommentDeleteDialogue,
              callback: function ($$v) {
                _vm.showCommentDeleteDialogue = $$v
              },
              expression: "showCommentDeleteDialogue",
            },
          }),
          _c(
            "v-row",
            [
              _c("v-col", [
                _c(
                  "div",
                  { staticClass: "mb-6", staticStyle: { "font-size": "20px" } },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "mr-2",
                        attrs: { color: "black", icon: "", large: "" },
                        on: {
                          click: function ($event) {
                            return _vm.$router.push("/students/announcements")
                          },
                        },
                      },
                      [_c("v-icon", [_vm._v("arrow_back")])],
                      1
                    ),
                    _vm._v(
                      " " +
                        _vm._s(
                          !_vm.$language
                            ? "Announcement Details"
                            : "বিস্তারিত নোটিশ "
                        ) +
                        " "
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticStyle: {
                      "max-height": "90vh",
                      "overflow-y": "auto",
                      "overflow-x": "hidden",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "announcement-details-card mb-6 pt-4 px-4",
                        attrs: { flat: "" },
                      },
                      [
                        _c(
                          "v-row",
                          {
                            staticClass: "pt-4",
                            staticStyle: { "padding-left": "30px" },
                            attrs: { justify: "space-between" },
                          },
                          [
                            _c(
                              "div",
                              { staticStyle: { "max-width": "660px" } },
                              [
                                _c(
                                  "div",
                                  { staticStyle: { "font-size": "18px" } },
                                  [_vm._v(_vm._s(_vm.announcement.title))]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "font-size": "12px",
                                      color: "#a4a4a4",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          !_vm.$language
                                            ? "Upload Date :"
                                            : "প্রকাশের তারিখ :"
                                        ) +
                                        " " +
                                        _vm._s(
                                          !_vm.$language
                                            ? _vm.announcement.date_time.split(
                                                "."
                                              )[0]
                                            : new Date(
                                                _vm.announcement.date_time.split(
                                                  "."
                                                )[0]
                                              ).toLocaleString("bn-BD", {
                                                dateStyle: "medium",
                                              })
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "padding-right": "20px",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "mt-1" },
                                  [
                                    _c(
                                      "v-chip",
                                      {
                                        staticStyle: {
                                          "font-size": "10px",
                                          "border-radius": "5px",
                                        },
                                        attrs: { color: "#0099DC1A" },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.announcement.course_title
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "v-row",
                          {
                            staticClass: "px-4",
                            staticStyle: {
                              "font-size": "12px",
                              color: "#5b5c5e",
                            },
                          },
                          [
                            _c("v-col", {
                              domProps: {
                                innerHTML: _vm._s(_vm.announcement.text),
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "ml-4",
                            staticStyle: { "font-size": "14px" },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  !_vm.$language
                                    ? "Comments from batches"
                                    : "ব্যাচ থেকে মন্তব্য"
                                ) +
                                " "
                            ),
                          ]
                        ),
                        _c(
                          "v-tabs",
                          {
                            staticClass: "mt-3 ml-4",
                            staticStyle: {
                              border: "#00000033 1px solid",
                              "border-radius": "10px",
                              "max-width": "96%",
                            },
                            attrs: { "hide-slider": "" },
                            model: {
                              value: _vm.tab,
                              callback: function ($$v) {
                                _vm.tab = $$v
                              },
                              expression: "tab",
                            },
                          },
                          _vm._l(
                            _vm.announcement.shared_with_batches,
                            function (batch, i, j) {
                              return _c(
                                "v-tab",
                                {
                                  key: i,
                                  staticStyle: {
                                    "text-transform": "none",
                                    "max-height": "20px",
                                    "min-height": "20px",
                                    display: "inline-block",
                                    "margin-top": "10px",
                                  },
                                },
                                [
                                  _c(
                                    "v-chip",
                                    {
                                      staticStyle: {
                                        "border-radius": "6px",
                                        height: "26px",
                                        "font-weight": "normal",
                                        "font-size": "12px",
                                      },
                                      style: !_vm.isTabActive(j)
                                        ? `color: #000000;`
                                        : ``,
                                      attrs: {
                                        dark: _vm.isTabActive(j),
                                        outlined: !_vm.isTabActive(j),
                                        color: _vm.isTabActive(j)
                                          ? "#0099DC"
                                          : "#D4D4D4",
                                      },
                                    },
                                    [_vm._v(_vm._s(batch) + " ")]
                                  ),
                                ],
                                1
                              )
                            }
                          ),
                          1
                        ),
                        _c(
                          "v-tabs-items",
                          {
                            model: {
                              value: _vm.tab,
                              callback: function ($$v) {
                                _vm.tab = $$v
                              },
                              expression: "tab",
                            },
                          },
                          _vm._l(_vm.batch_ids, function (batch_id, i) {
                            return _c(
                              "v-tab-item",
                              { key: i },
                              [
                                _c("comments", {
                                  key: _vm.refreshComments,
                                  attrs: {
                                    "announcement-id":
                                      _vm.announcement.announcement_id,
                                    "course-id": _vm.announcement.course_id,
                                    "batch-id": batch_id,
                                  },
                                  on: {
                                    "show-comment-delete-dialogue": function (
                                      $event
                                    ) {
                                      _vm.showCommentDeleteDialogue = true
                                      _vm.commentIdToDelete = $event
                                    },
                                  },
                                }),
                              ],
                              1
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }