import { Backend } from "~/utils/backend";
const $backend = new Backend(process.env.VUE_APP_BACKEND_EDU_STUDENTS_URL);
import { checkStatus } from "/global/utils/helpers";
export default {
  URL: "announcements/",
  get_comments(announcement_id, course_id, batch_id, opts = {}) {
    return $backend
      .post("students_get_comments", {
        announcement_id: announcement_id,
        course_id: course_id,
        batch_id: batch_id,
      })
      .then(checkStatus)
      .then(r => r.data.content.comments);
  },
  post_comments(
    comment_id = undefined,
    announcement_id,
    course_id,
    batch_id,
    text,
    opts = {},
  ) {
    let data = {
      announcement_id: announcement_id,
      course_id: course_id,
      batch_id: batch_id,
      text: text,
    };
    if (comment_id !== undefined) {
      data["comment_id"] = comment_id;
    }
    return $backend
      .post("students_post_comments", data)
      .then(checkStatus)
      .then(r => r.data.content.comments);
  },
  delete_comments(comment_id, announcement_id) {
    return $backend
      .post("students_delete_comments", {
        comment_id: comment_id,
        announcement_id: announcement_id,
      })
      .then(checkStatus)
      .then(r => r.data.content.comments);
  },
  get(announcement_id, course_id, opts = {}) {
    return $backend
      .post("students_get_announcement", {
        announcement_id: announcement_id,
        course_id: course_id,
      })
      .then(checkStatus)
      .then(r => r.data.content.announcement);
  },
  /**
   * @return Promise<AxiosResponse>
   * @param course_ids
   * @param partner_code
   * @param opts
   */

  list(last_fetch_time = undefined, opts = {}) {
    return $backend
      .post(
        "students_get_announcements_optimized",
        {
          last_fetch_time: last_fetch_time,
        },
        { shouldSendPartnerCode: false },
      )
      .then(checkStatus)
      .then(r => r.data.content);
  },

  /**
   * @return Promise
   * @param courseId
   * @param sharedWithBatches
   * @param announcerId
   * @param announcementText
   * @param title
   * @param text
   * @param headers
   */
};
