<template>
  <div v-if="renderNow">
    <v-row style="font-size: 12px; color: #202020;" class="pl-8"> </v-row>
    <v-row class="px-7 mt-4">
      <v-text-field
          ref="commentbox"
          v-model="text"
          :label="!$language ? 'Write your comment...' : 'আপনার মন্তব্য লিখুন'"
          outlined
          dense
          style="font-size: 16px"
          height="30px"
          @keydown.enter="post_comments(undefined, text)"
      ></v-text-field>
    </v-row>
    <div class="comment-section">
      <div v-for="(comment, i) in comments" :key="i" class="mb-4 ml-4 comment">
        <v-row>
          <div cols="1">
            <div class="inner-circle mt-7 mr-2" style="">
              {{ comment.commenter_name[0] }}
            </div>
          </div>
          <v-col class="ml-0">
            <div class="ml-1">
              <v-row
                  class="commenter-name"
              >
                {{ comment.commenter_name }}
              </v-row>
              <v-row>
                <v-col cols="10" class="comment-text">{{ comment.text }}</v-col>
                <v-col cols="2" class="comment-actions-button"
                >
                  <v-menu
                      v-if="
                      comment.commenter_id === $store.state.user.user.user_id
                    "
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                          size="28"
                          v-bind="attrs"
                          v-on="on"
                          @click="showEditDeleteMenu = !showEditDeleteMenu"
                      >mdi-dots-vertical</v-icon
                      >
                    </template>
                    <v-list>
                      <v-list-item
                          style="font-size: 12px; color: #0089CB; min-height: 26px; max-height: 26px;"
                          @click="
                          editText = comment.text;
                          startEditingComment(i);
                        "
                      >
                        <v-icon style="color: #0089CB;">edit</v-icon>
                        <div style="color: #0089CB;">
                          &nbsp;{{ !$language ? "Edit" : "এডিট করুন" }}
                        </div>
                      </v-list-item>
                      <v-divider
                          style="margin: 0px; background-color: #0089CB; opacity: 0.1"
                          class="my-2"
                      ></v-divider>
                      <v-list-item
                          style="font-size: 12px; color: #0089CB; min-height: 26px; max-height: 26px;"
                          @click="
                          $emit(
                            'show-comment-delete-dialogue',
                            comment.comment_id,
                          )
                        "
                      >
                        <v-icon style="color: #0089CB;">delete</v-icon>
                        <div style="color: #0089CB;">
                          &nbsp;{{ !$language ? "Delete" : "ডিলিট করুন" }}
                        </div>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-col>
              </v-row>
              <div v-if="editing[i] === false">
                <v-row
                    style=""
                ></v-row
                >
                <v-row class="mt-4">
                  <div
                      style="width: 70px;"
                      class="ml-0"
                      @click="reply(comment.commenter_name)"
                  >
                    <v-icon size="16">reply</v-icon>
                    <span class="ml-1 mt-1" style="font-size: 12px">{{
                        !$language ? "Reply" : "উত্তর দিন"
                      }}</span>
                  </div>
                  <div>
                    <span style="font-size: 12px;" class="mt-2">{{
                        date_diff(comment.date_time)
                      }}</span>
                  </div>
                </v-row>
              </div>
              <div v-else class="ml-0 mr-5">
                <v-text-field
                    v-model="editText"
                    outlined
                    dense
                    @keydown.enter="
                    post_comments(comment.comment_id, editText);
                    editing[i] = false;
                  "
                >
                </v-text-field>
                <div style="float: right; justify-items: flex-end;">
                  <v-btn outlined color="red" @click="stopEditingComment(i)">{{
                      !$language ? "cancel" : "বাতিল করুন"
                    }}</v-btn>
                  <v-btn
                      class="ml-4"
                      style="border-radius: 5px"
                      color="#0099DC"
                      dark
                      @click="
                      post_comments(comment.comment_id, editText);
                      editing[i] = false;
                    "
                  >{{ !$language ? "save" : "সেভ করুন" }}</v-btn
                  >
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import announcementService from "#ef/announcement/services/AnnouncementService";

export default {
  name: "Comments",
  props: {
    announcementId: String,
    courseId: String,
    batchId: String,
  },
  data() {
    return {
      showEditDeleteMenu: false,
      editing: [],
      editText: undefined,
      comments: [],
      text: undefined,
      renderNow: false,
    };
  },
  async created() {
    await this.get_comments();
    this.renderNow = true;
  },
  methods: {
    startEditingComment(i) {
      this.editing[i] = true;
      this.$forceUpdate();
    },
    stopEditingComment(i) {
      this.editing[i] = false;
      this.$forceUpdate();
    },
    reply(reply_target) {
      if (this.text === undefined || this.text === "") {
        this.text = reply_target + " ";
        this.$refs.commentbox.focus();
      }
    },
    async get_comments() {
      try {
        this.comments = await announcementService.get_comments(
          this.announcementId,
          this.courseId,
          this.batchId,
        );
        for (let i = 0; i < this.comments.length; i++) {
          this.editing.push(false);
        }
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      }
    },
    async post_comments(comment_id = undefined, text) {
      if (text === "" || text.trim() === "") return; // do not post empty comment or only whitespace
      try {
        this.comments = await announcementService.post_comments(
          comment_id,
          this.announcementId,
          this.courseId,
          this.batchId,
          text,
        );
      } catch (e) {
        if (
          e.response &&
          e.response.data &&
          e.response.data.error &&
          e.response.data.error.text
        ) {
          this.$root.$emit("alert", [undefined, e.response.data.error.text[0]]);
        } else {
          this.$root.$emit("alert", [undefined, e.message]);
        }
      }
      this.text = "";
      await this.get_comments();
    },
    date_diff(date) {
      var diff = moment(date).from(
        moment()
          .utc()
          .format("YYYY-MM-DD HH:mm:ss"),
      );
      return diff;
    },
  },
};
</script>

<style scoped>
.comment {
  padding: 12px;
  padding-top: 0px;
  padding-left: 28px;
  padding-right: 28px;
  font-size: 12px;
  color: #707070;
  min-height: 34px;
  background: #f3f7ff 0% 0% no-repeat padding-box;
  border-radius: 6px;
  opacity: 1;
}

.inner-circle {
  width: 46px;
  height: 46px;
  padding-left: 17px;
  border-radius: 50%;
  border-style: solid;
  border-width: 2px;
  line-height: 44px;
  border-color: #0099dc;
  background-color: #ebf2ff;
  color: #0099dc;
  font-size: 14px;
}

.commenter-name {
  padding-top: 36px;
  font-size: 15px;
  text-align: center;
  max-width: 640px;
  max-height: 60px;
  font-weight: bold;
  color: #5B5C5E;
  display: flex;
}

@media screen and (min-width: 200px) and (max-width: 1199px) {
  .commenter-name {
    font-size: 12px;
    max-width: 30ch;
  }
}

.comment-text {
  font-size: 12px;
  color: grey;
  font-weight: bold;
  padding: 0px;
}

@media screen and (min-width: 200px) and (max-width: 1199px) {
  .comment-text {
    font-size: 12px;
    font-weight: bold;
    margin-top: 4px;
  }
}

.comment-section {
  max-height: 36vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

@media screen and (min-width: 200px) and (max-width: 1199px) {
  .comment-section {
    margin-top: 20px;
  }
}

.comment-actions-button {
  display:flex;
  justify-content: end;
  padding: 0px;
}

</style>
